import { IntegrationDestination } from '@hints/types';

import { hubspot, pipedrive, salesforce } from '../outputs';
import { DestinationMetadata, OutputIntegrationMetadata } from '../types';

const OUTPUTS: Record<
  string,
  OutputIntegrationMetadata<IntegrationDestination>
> = {
  hubspot,
  salesforce,
  pipedrive,
};

export function useOutputMetadata<T extends IntegrationDestination>(
  outputIntegration: string | null | undefined,
): OutputIntegrationMetadata<T> | null {
  return OUTPUTS[outputIntegration ?? ''] ?? null;
}

export function useDestination<T extends IntegrationDestination>(
  destination: T,
): DestinationMetadata<T> | null {
  const metadata = OUTPUTS[destination];
  if (metadata) {
    const connector = metadata.connectorHook();
    return { ...metadata, connector, key: destination };
  }
  return null;
}

export function useAllOutputs(): ({
  key: IntegrationDestination;
} & OutputIntegrationMetadata<IntegrationDestination>)[] {
  return Object.keys(OUTPUTS).map((key) => ({
    ...OUTPUTS[key],
    key: key as IntegrationDestination,
  }));
}
