import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import PipedriveIcon from '@hints/shared/icons/integrations/Pipedrive.svg';

import PipedriveTutorialGif from '../../../../assets/TG-Pepidrive.png';
import PipedriveStep1 from '../../../../assets/instructions/pipedrive/pipedrive_instruction.png';
import PipedriveStep2 from '../../../../assets/instructions/pipedrive/step-2.png';
import PipedriveStep3 from '../../../../assets/instructions/pipedrive/step-3.png';
import PipedriveStep4 from '../../../../assets/instructions/pipedrive/step-4.png';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { PipedriveSetup } from '../setups/PipedriveSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const CLIENT_ID = 'c365ad6e7caf8721';
const AUTH_URL = `https://oauth.pipedrive.com/oauth/authorize?client_id=${CLIENT_ID}`;

const usePipedriveConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authorizePipedrive] = useHttpsCallable('authorizePipedrive');
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl: AUTH_URL,
    onComplete: async (code, redirectUri) => {
      await authorizePipedrive({ code, redirectUri });
    },
  });

  const disconnect = () => {
    dispatch(
      updateProfile({ pipedrive: FieldValue.delete() as unknown as undefined }),
    );
  };

  return {
    isConnected: !!user?.pipedrive?.accessToken,
    connect: startAuth,
    disconnect,
    isLoading,
    error,
  };
};

export const pipedrive: OutputIntegrationMetadata<'pipedrive'> = {
  title: 'Pipedrive',
  logoUrl: PipedriveIcon,
  connectorHook: usePipedriveConnector,
  defaultTagName: 'pipedrive',
  setupComponent: PipedriveSetup,
  tutorial: {
    gif: (
      <img
        src={PipedriveTutorialGif}
        alt=""
        className="w-full h-[200px] rounded-lg"
      />
    ),
    examples: [
      '✅ Now you can create and update your deals. Just tell the Assistant what you want in your own words. See examples below:',
      '🤖 Create a new deal Microsoft Bill Gates bill@microsoft.com',
      '🤖 Remind me to follow up with Bill Gates from Microsoft on March 17th',
      '🤖 Call John from IBM tomorrow',
      '🤖 Add a note to IBM: Meeting went great. I’m waiting for the final approval',
      '👥 To use this bot with your team, add it to your group and use #pipedrive in your requests. Example: Move IBM to Demo Scheduled #pipedrive',
    ],
  },
  instructions: (
    <></>
    // <div className="space-y-4">
    //   <p>
    //     Click the “<b>Login to Pipedrive</b>” button below and choouse your
    //     Pipedrive account
    //   </p>
    //   <p>
    //     Select your Company or Team you would like to sync with Hints and give
    //     our assistant access to your pipeline
    //   </p>
    //   <img
    //     src={PipedriveStep1}
    //     alt="step2"
    //     className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
    //   />
    // </div>
    // <div className="mb-2">
    //   <div>
    //     <b>Step 1.</b> Click the “<b>Login to Pipedrive</b>” button below
    //   </div>
    //   <div>
    //     <span>
    //       <b>Step 2.</b> Choose your account in Pipedrive popup window
    //     </span>
    //     <img
    //       src={PipedriveStep2}
    //       alt="step2"
    //       className="max-h-[300px] middle:max-h-[150px] mt-2 rounded-lg"
    //     />
    //   </div>
    //   <div>
    //     <span>
    //       <b>Step 3.</b> Select your company / team to sync with Hints
    //     </span>
    //     <img
    //       src={PipedriveStep3}
    //       alt="step2"
    //       className="max-h-[300px] middle:max-h-[80px] mt-2 rounded-lg"
    //     />
    //   </div>
    //   <div>
    //     <span>
    //       <b>Step 4.</b> Give Hints bot access to update your pipeline
    //     </span>
    //     <img
    //       src={PipedriveStep4}
    //       alt="step2"
    //       className="max-h-[300px] middle:max-h-[100px] mt-2 rounded-lg"
    //     />
    //   </div>
    // </div>
  ),
};
