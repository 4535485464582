import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OnboardingHubspot1 from '../../../assets/onboarding_hubspot_1.png';
import OnboardingHubspot2 from '../../../assets/onboarding_hubspot_2.png';
import OnboardingHubspot3 from '../../../assets/onboarding_hubspot_3.png';
import OnboardingHubspot4 from '../../../assets/onboarding_hubspot_4.png';
import { Button, ResponsiveScreen } from '../../components';
import { useQueryParam } from '../../hooks/useQueryParam';

const slides = [
  {
    color: 'bg-[#d8ceff] md:bg-[#d8ceff]',
    image: OnboardingHubspot1,
    text: 'Create deals and contacts',
  },
  {
    color: 'bg-[#C6E0F5] md:bg-[#C6E0F5]',
    image: OnboardingHubspot2,
    text: 'Add a note, update stage, close date, amount, etc.',
  },
  {
    color: 'bg-[#CBE5DE] md:bg-[#CBE5DE]',
    image: OnboardingHubspot3,
    text: 'Message the bot directly or add it to the group chat',
  },
  {
    color: 'bg-[#F0D9CA] md:bg-[#F0D9CA]',
    image: OnboardingHubspot4,
    text: 'Talk to the bot like you talk to a human',
  },
];

export const HubspotOnboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const emailFromQueryParam = useQueryParam('email');
  if (emailFromQueryParam) {
    localStorage?.setItem('emailForSignIn', emailFromQueryParam);
  }

  const navigate = useNavigate();

  // const user = useAppSelector(profileSelector);

  // useEffect(() => {
  //   if (!isEmpty(user) && user.integrationOnboardingStatus?.completed) {
  //     navigate('/');
  //   }
  // }, [user]);

  const handleSlideNext = (step: number) => {
    if (step <= slides.length - 1) {
      setCurrentStep(step);
    } else {
      setCurrentStep(0);
      navigate('/integrations/output/hubspot');
    }
  };

  return (
    <ResponsiveScreen classname={slides[currentStep]?.color}>
      <div className="relative w-full h-full flex flex-col justify-end items-center py-12 md:py-3 space-y-6 overflow-x-hidden">
        {slides.map(({ image, text }, index) => (
          <div
            className={`w-full absolute top-6 md:top-3 flex flex-col items-center space-y-4 justify-center transform transition-all duration-300 ease-out ${
              index === currentStep
                ? 'translate-x-0'
                : index < currentStep
                ? '-translate-x-full'
                : 'translate-x-full'
            }`}
          >
            <img
              src={image}
              alt="Flows"
              className="max-h-[270px] middle:max-h-[360px]"
            />
            <h2 className="text-center max-w-20 w-full font-sans font-bold text-xl">
              {text}
            </h2>
          </div>
        ))}
        <ul className="w-full max-w-[200px] space-x-4 flex justify-between items-start">
          {slides.map((item, index) => (
            <li
              className={`${
                index === currentStep
                  ? 'flex-[3] opacity-100'
                  : 'flex-1 opacity-30'
              } w-full h-2 rounded-full bg-gray-900 transition-all duration-300 ease-in-out cursor-pointer`}
              onClick={() => handleSlideNext(index)}
            />
          ))}
        </ul>
        <Button
          text={currentStep === slides.length - 1 ? 'Great!' : 'Next'}
          isFull
          type="primary"
          isRounded
          size="big"
          onClick={() => handleSlideNext((currentStep + 1) as any)}
        />
      </div>
    </ResponsiveScreen>
  );
};

export default HubspotOnboarding;
