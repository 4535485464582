import axios, { AxiosRequestConfig } from 'axios';
import firebase from 'firebase/compat/app';
import { FC, createElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  createIntegration,
  integrationsArraySelector,
  profileSelector,
  removeIntegration,
  startLoadingApp,
  stopLoadingApp,
  updateIntegration,
  updateProfile,
} from '@hints/client';
import { clickupConnectionNote } from '@hints/constants';
import { ReactComponent as CheckIcon } from '@hints/shared/icons/check-cr-fr.1.svg';
import { ReactComponent as MoreIcon } from '@hints/shared/icons/more-cr-fr.2.svg';
import { ReactComponent as PlusIcon } from '@hints/shared/icons/plus-cr-fr.1.svg';
import { ReactComponent as QuestionMark } from '@hints/shared/icons/question-mark-cr-fr.2.svg';
import { ReactComponent as TrashIcon } from '@hints/shared/icons/trash-can.2.svg';
import {
  GoogleCalendarIntegration,
  Integration,
  IntegrationSource,
  IntegrationWithId,
} from '@hints/types';

import { ampli } from '../../../ampli';
import inputSuccessImage from '../../../assets/input-success.svg';
import integrationSuccessImage from '../../../assets/integration-success.png';
import { environment } from '../../../environments/environment';
import { Button, Header, Intercom, ResponsiveScreen } from '../../components';
import { Modal, ModalHeader } from '../../components/Modal';
import { useAppDispatch, useAppSelector, useHttpsCallable } from '../../hooks';
import useQuery from '../../hooks/useQuery';
import { useQueryParam } from '../../hooks/useQueryParam';
import { ConnectionButton } from './ConnectionButton';
import { FlowSection } from './FlowSection';
import { TagSetup } from './TagSetup';
import { useConnectorInitializer } from './hooks/useConnectorInitializer';
import { useAllInputs } from './hooks/useInputMetadata';
import { useIntegration } from './hooks/useIntegration';
import { useOutputMetadata } from './hooks/useOutputMetadata';
import { DraftIntegration, InputIntegrationMetadata } from './types';

import FieldValue = firebase.firestore.FieldValue;

const RemoveSource: FC<{ handlePress: () => void }> = ({ handlePress }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className="relative">
      <MoreIcon width={20} height={20} onClick={() => setVisible(!visible)} />
      <div className={`absolute top-5 right-0 ${visible ? '' : 'invisible'}`}>
        <Button
          text="Remove"
          onClick={handlePress}
          type="danger"
          size="small"
        />
      </div>
    </div>
  );
};

const InputModal: FC<{
  metadata: InputIntegrationMetadata & { key: IntegrationSource };
  onClose: () => void;
  sources?: IntegrationSource[];
  setSources: (sources: IntegrationSource[]) => void;
}> = ({ metadata, onClose, sources, setSources }) => {
  const connector = metadata.connectorHook();
  const isInitialized = useConnectorInitializer(connector);
  const [stage, setStage] = useState<'connect' | 'settings' | 'success'>();

  const onConnect = () => {
    setSources([...(sources ?? []), metadata.key]);
    if (metadata.title === 'Raycast') {
      setStage('connect');
    } else {
      setStage('success');
    }
  };

  const onDisconnect = () => {
    setSources(sources?.filter((s) => s !== metadata.key) ?? []);
    onClose();
  };

  useEffect(() => {
    if (sources?.includes(metadata.key)) {
      setStage('settings');
    } else if (connector.isConnected) {
      onConnect();
    } else {
      setStage('connect');
    }
  }, []);

  useEffect(() => {
    if (stage === 'connect' && connector.isConnected) {
      onConnect();
    }
  }, [connector.isConnected]);

  useEffect(() => {
    if (
      stage === 'success' ||
      (stage === 'connect' && metadata.title === 'Raycast')
    ) {
      ampli.sourceEnabled({ source: metadata.key as any, product: 'hints.so' });
    }
  }, [stage]);

  switch (stage) {
    case 'connect':
      return (
        <Modal
          isShown
          hasCancel
          header={
            <ModalHeader iconUrl={metadata.logoUrl} title={metadata.title} />
          }
          buttons={[]}
          onClose={onClose}
        >
          <ConnectionButton
            isInitialized={isInitialized}
            connector={connector}
          />
        </Modal>
      );
    case 'success':
      return (
        <Modal
          isShown
          header={
            <ModalHeader iconUrl={metadata.logoUrl} title={metadata.title} />
          }
          buttons={[{ title: 'Continue', onClick: onClose }]}
          onClose={onClose}
        >
          <div className="my-5 flex flex-col justify-center items-center">
            <img src={inputSuccessImage} alt="Success" className="h-60" />
            <span className="mt-2">
              {metadata.title} is enabled! Please finish the flow setup.
            </span>
            {metadata?.successModalExtraText ? (
              <span className="mt-2">{metadata?.successModalExtraText}</span>
            ) : null}
          </div>
        </Modal>
      );
    case 'settings':
      return (
        <Modal
          isShown
          hasCancel
          header={
            <ModalHeader
              iconUrl={metadata.logoUrl}
              title={metadata.title}
              rightContent={
                <RemoveSource
                  handlePress={() => {
                    connector?.disconnect?.();
                    onDisconnect();
                  }}
                />
              }
            />
          }
          buttons={[
            {
              type: 'danger',
              title: 'Disable',
              onClick: onDisconnect,
            },
          ]}
          onClose={onClose}
        >
          <p className="w-full text-center mb-2">
            {metadata.title} is enabled for this flow.
          </p>
        </Modal>
      );
    default:
      return null;
  }
};

const SourceSelector: FC<{
  sources?: IntegrationSource[];
  setSources: (sources: IntegrationSource[]) => void;
  isNewIntegration: boolean;
}> = ({ sources, setSources, isNewIntegration }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const inputs = useAllInputs();
  const { output } = useParams();

  const query = useQuery();

  const [getCustomToken] = useHttpsCallable('getCustomToken');

  const user = useSelector(profileSelector);

  const [currentInputMetadata, setCurrentInputMetadata] = useState<
    InputIntegrationMetadata & { key: IntegrationSource }
  >();

  const connectedInputs = inputs.filter(
    ({ connectorHook }) => connectorHook?.().isConnected,
  );

  const onInputPress = async (
    inputMetadata: InputIntegrationMetadata & {
      key: IntegrationSource;
    },
  ) => {
    setCurrentInputMetadata(inputMetadata);
    if (
      ['sms', 'whatsapp', 'telegram', 'whatsapp-group'].includes(
        inputMetadata.key,
      ) &&
      !connectedInputs?.find((input) => input?.key === inputMetadata.key)
    ) {
      const token = await getCustomToken({});
      const link = `https://i.hints.so/${location?.pathname}?jwtToken=${token?.data}&input=${inputMetadata.key}`;

      const config: AxiosRequestConfig = {
        method: 'POST',
        url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.firebaseConsoleWebAPIKey}`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          dynamicLinkInfo: {
            domainUriPrefix: 'https://app.hints.so/link',
            link,
          },
          suffix: { option: 'UNGUESSABLE' },
        },
      };

      const { data } = await axios(config);
      if (data?.shortLink) {
        dispatch(
          updateProfile({
            botRedirectUrl: data.shortLink,
            botRedirectOutput: output as Integration['destination'],
          }),
        );
      }
    }
  };

  const resetBotRedirectUrl = () => {
    if (user?.botRedirectUrl) {
      dispatch(
        updateProfile({
          botRedirectUrl: FieldValue.delete() as unknown as undefined,
          botRedirectOutput: FieldValue.delete() as unknown as undefined,
        }),
      );
    }
  };

  useEffect(() => {
    if ((sources === null || sources === undefined) && !isNewIntegration) {
      setSources(connectedInputs.map((i) => i.key));
    }
  }, [setSources]);

  useEffect(() => {
    const source = query.get('input');
    const input = inputs.find((i) => i.key === source);
    if (input) {
      setCurrentInputMetadata(input);
    }
  }, []);

  useEffect(() => () => resetBotRedirectUrl(), []);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-2.5">
        {inputs.map((i) => {
          const isLinked = sources?.includes(i.key);
          return (
            <button
              key={i.key}
              type="button"
              className={`relative w-11 h-11 p-1 flex justify-center items-center border ${
                isLinked
                  ? 'border-gray-400 bg-gray-100 hover:border-gray-600 '
                  : 'border-gray-200 bg-transparent hover:bg-neutral-50 hover:border-neutral-400 '
              } transition-colors duration-300 ease-in-out rounded-xl`}
              onClick={() => onInputPress(i)}
            >
              <img src={i.logoUrl} alt={i.title} />
              <div className="absolute bottom-1 right-1">
                {isLinked ? (
                  <CheckIcon
                    primary="#fff"
                    secondary="#18181B"
                    className="w-3.5 h-3.5 text-white"
                  />
                ) : (
                  <PlusIcon className="w-3.5 h-3.5 text-white" />
                )}
              </div>
            </button>
          );
        })}
      </div>
      {currentInputMetadata ? (
        <InputModal
          metadata={currentInputMetadata}
          onClose={() => {
            resetBotRedirectUrl();
            setCurrentInputMetadata(undefined);
          }}
          sources={sources}
          setSources={setSources}
        />
      ) : null}
    </>
  );
};

const SuccessModal = ({
  integration,
  isShown,
  isFromBot,
}: {
  isShown: boolean;
  integration: DraftIntegration;
  isFromBot: boolean;
}) => {
  const navigate = useNavigate();
  const inputs = useAllInputs();
  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length < 2;
  const connectors = inputs.map((i) => ({
    key: i.key,
    ...i.connectorHook(),
  }));
  const inputConnector = connectors.find(
    (i) => i.key === integration.sources?.[0],
  );
  return (
    <Modal
      isShown={isShown}
      buttons={[
        {
          title: 'Great!',
          onClick: () =>
            isFromBot && inputConnector
              ? inputConnector.open?.()
              : navigate(
                  integration?.id
                    ? '/'
                    : `/tutorials/output/${integration.destination}?afterFlowCreated=true`,
                ),
        },
      ]}
    >
      <div className="w-full mb-3">
        <div className="my-5 flex justify-center">
          <img src={integrationSuccessImage} alt="Success" className="h-60" />
        </div>
        The flow is enabled!
        {!isFromBot && !firstIntegrationSetup ? (
          <>
            <br />
            Don't forget to use the{' '}
            <span className="font-bold">#{integration.tagName}</span> hashtag
            while sending data.
          </>
        ) : null}
      </div>
    </Modal>
  );
};

const Output = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(profileSelector);

  const initialBot = useQueryParam('bot') as IntegrationSource | null;
  const isFromBot = !!initialBot;

  const [publishButtonPressed, setPublishButtonPressed] = useState(false);

  const { output, id } = useParams();
  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState<boolean>(false);

  const [isIntegrationCompleted, setIsIntegrationCompleted] = useState(false);
  const [isIntegrationCanBeSaved, setIsIntegrationCanBeSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const outputMetadata = useOutputMetadata(output);
  const outputConnector = outputMetadata?.connectorHook?.();
  const isInitialized = useConnectorInitializer(outputConnector);

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;
  const [integration, setIntegration] = useIntegration(id, {
    destination: output ?? '',
    tagName:
      firstIntegrationSetup || isFromBot
        ? outputMetadata?.defaultTagName ?? ''
        : '',
    userId: user?.id,
    sources: initialBot ? [initialBot] : undefined,
  });
  const setIntegrationData = (data: any) => {
    setIntegration((prev) => ({ ...prev, ...data }));
  };
  const setTagName = (tagName: string) => {
    setIntegration((prev) => ({ ...prev, tagName }));
  };

  const tagNameUsed = userIntegrations.find(
    (i) => i.tagName === integration?.tagName && i.id !== integration.id,
  );

  const showErrorMessage = tagNameUsed && !isLoading && !isIntegrationCompleted;
  const destinationConnected = integration && outputConnector?.isConnected;

  const onSavePress = async () => {
    setPublishButtonPressed(true);
    if (
      !integration ||
      !isIntegrationCanBeSaved ||
      isLoading ||
      !integration?.tagName ||
      !integration?.sources?.length ||
      showErrorMessage
    ) {
      return;
    }

    dispatch(startLoadingApp());
    setIsLoading(true);
    if (integration.id) {
      await dispatch(
        updateIntegration({
          ...integration,
          credentials: user.googleAuthTokens,
        } as IntegrationWithId),
      );
    } else {
      await dispatch(
        createIntegration({
          ...integration,
          credentials: user.googleAuthTokens,
        } as Integration),
      );

      if (!user.integrationOnboardingStatus?.completed) {
        await dispatch(
          updateProfile({
            id: user.id,
            integrationOnboardingStatus: { completed: true },
          }),
        );
      }
    }
    setIsIntegrationCompleted(true);
    setIsLoading(false);
    dispatch(stopLoadingApp());
  };

  const onDeletePress = async () => {
    if (!integration || isLoading) {
      return;
    }
    setIsConfirmationModalShown(true);
  };

  useEffect(() => {
    if (integration?.destination === 'google-calendar') {
      if (integration?.id) {
        const { credentials } = integration as GoogleCalendarIntegration;
        if (credentials) {
          dispatch(
            updateProfile({
              googleAuthTokens: credentials,
            }),
          );
        }
      } else {
        outputConnector?.disconnect?.();
      }
    }
  }, [integration]);

  useEffect(() => {
    // for integrations without SetupComponent
    if (!outputMetadata?.setupComponent && !!outputConnector?.isConnected) {
      setIsIntegrationCanBeSaved(!!outputConnector?.isConnected);
    }
  }, [outputConnector?.isConnected, outputMetadata?.setupComponent]);

  useEffect(() => {
    if (localStorage.getItem('tagName') && integration !== null) {
      setIntegration({
        ...integration,
        tagName: localStorage.getItem('tagName') as string,
      });
      localStorage.setItem('tagName', '');
    }
  }, [integration, setIntegration]);

  if (!outputMetadata || !outputConnector) {
    return <Navigate to="/not-found" />;
  }

  return (
    <ResponsiveScreen small={isFromBot}>
      <div className="relative w-full h-full">
        <div className="relative h-full flex flex-col">
          {!isFromBot ? (
            <Header
              title="Flow settings"
              progress={!user?.integrationOnboardingStatus ? 75 : undefined}
            />
          ) : null}
          <div className="w-full flex flex-col md:flex-1 mb-4 md:mb-0 space-y-3">
            {!isFromBot ? (
              <>
                <div>
                  <FlowSection
                    completed={!!integration?.sources?.length}
                    step={1}
                    error={
                      publishButtonPressed && !integration?.sources?.length
                    }
                    errorText="choose one or more sources to continue"
                  >
                    Select a messenger to send from
                  </FlowSection>
                  <SourceSelector
                    sources={integration?.sources}
                    setSources={(sources) => {
                      setIntegration((prev) => ({ ...prev, sources }));
                    }}
                    isNewIntegration={!integration?.id}
                  />
                </div>
              </>
            ) : null}
            <div>
              <FlowSection
                completed={outputConnector.isConnected}
                step={firstIntegrationSetup ? 2 : 3}
                error={
                  (publishButtonPressed && !outputConnector.isConnected) ||
                  !!outputConnector.error
                }
                errorText={
                  outputConnector.error
                    ? outputConnector.error.message
                    : 'sign in to service to continue'
                }
              >
                {outputMetadata.firstStepName ??
                  `Sign in to ${outputMetadata.title}`}
              </FlowSection>
              <ConnectionButton
                isInitialized={isInitialized}
                connector={outputConnector}
                integrationTitle={outputMetadata.title}
              />
              {isInitialized ? null : clickupConnectionNote}
            </div>
            <Intercom className="bottom-24 md:bottom-10" />
            {destinationConnected && outputMetadata?.setupComponent
              ? createElement(outputMetadata.setupComponent, {
                  integration,
                  setIntegrationData,
                  setIsIntegrationCanBeSaved,
                  publishButtonPressed,
                })
              : null}
            {destinationConnected ? (
              <SuccessModal
                isShown={isIntegrationCompleted}
                integration={integration}
                isFromBot={isFromBot}
              />
            ) : null}

            {integration?.id ? (
              <Modal
                isShown={isConfirmationModalShown}
                buttons={[
                  {
                    title: 'Delete',
                    onClick: async () => {
                      if (!integration || isLoading) {
                        return;
                      }
                      setIsLoading(true);
                      dispatch(startLoadingApp());
                      await dispatch(
                        removeIntegration(integration as IntegrationWithId),
                      );
                      setIsConfirmationModalShown(false);
                      setIsLoading(false);
                      dispatch(stopLoadingApp());
                      navigate('/');
                    },
                    type: 'danger',
                  },
                  {
                    title: 'Close',
                    onClick: () => setIsConfirmationModalShown(false),
                    type: 'inline',
                  },
                ]}
              >
                <div className="w-full mb-3">
                  Are you sure you want to delete the flow?
                </div>
              </Modal>
            ) : null}
          </div>

          {!isFromBot || destinationConnected ? (
            <div className="mt-6 pb-3 text-center space-y-3">
              {integration?.id ? (
                <Button
                  leftContent={
                    <TrashIcon
                      primary="#FCA5A5"
                      secondary="#EF4444"
                      className="w-5 h-5"
                    />
                  }
                  text="Delete"
                  type="danger"
                  onClick={onDeletePress}
                  isFull
                  isRounded
                />
              ) : null}
              <Button
                leftContent={
                  <CheckIcon
                    primary="#E4E4E7"
                    secondary="#52525B"
                    className="w-5 h-5 text-white"
                  />
                }
                text={
                  // eslint-disable-next-line no-nested-ternary
                  isFromBot
                    ? `Finish setup`
                    : integration?.id
                    ? 'Update'
                    : 'Publish flow'
                }
                onClick={onSavePress}
                type="primary"
                isFull
                isRounded
              />
            </div>
          ) : null}
        </div>
      </div>
    </ResponsiveScreen>
  );
};

export default Output;
