import { useEffect, useState } from 'react';

import { profileSelector } from '@hints/client';
import { ReactComponent as GoogleIcon } from '@hints/shared/icons/auth/google.svg';
import { ReactComponent as LogoutIcon } from '@hints/shared/icons/logout.1.svg';

import { Button, LoaderIcon } from '../../components';
import { useAppSelector, useHttpsCallable } from '../../hooks';
import { IntegrationConnector } from './types';

export const ConnectionButton = ({
  isInitialized,
  connector,
  integrationTitle,
  isAlwaysConnected,
}: {
  isInitialized: boolean;
  connector: IntegrationConnector;
  integrationTitle?: string;
  isAlwaysConnected?: boolean;
}) => {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [isConnecting, setIsConnecting] = useState(false);
  const isHubspot = integrationTitle?.toLowerCase() === 'hubspot';
  const isPipedrive = integrationTitle?.toLowerCase() === 'pipedrive';
  const user = useAppSelector(profileSelector);
  const [updateHubspotContact] = useHttpsCallable(
    'updateHubspotContactCallable',
  );

  useEffect(() => {
    if (isConnecting && connector.isConnected) {
      setIsConnecting(false);
    }
  }, [connector.isConnected && !isAlwaysConnected]);

  if (connector.isConnected && !isAlwaysConnected) {
    return (
      <div className="w-full flex flex-col items-center space-y-3 max-w-[410px]">
        <button
          type="button"
          onClick={() => connector?.disconnect?.()}
          className="w-full py-2 px-4 flex items-center justify-center border hover:bg-gray-100 rounded-lg transition-colors border-gray-300 bg-white text-gray-500 cursor-pointer"
        >
          <LogoutIcon />
          <span className="ml-2 text-gray-900">Refresh Session (Logout)</span>
        </button>
      </div>
    );
  }

  if (!isInitialized && !isAlwaysConnected) {
    //  || isConnecting. пока лучше не ставить, так как через раз срабатывает авторизация
    return (
      <div className="flex justify-center py-2">
        <LoaderIcon />
      </div>
    );
  }

  const crmLoadingMessages = [
    'connecting...',
    `we're talking to ${integrationTitle}`,
    "don't close the tab",
  ];

  const getLoadingMessage = () => {
    setTimeout(() => {
      setLoadingMessageIndex(loadingMessageIndex + 1);
    }, 2000);
    return crmLoadingMessages[loadingMessageIndex % crmLoadingMessages.length];
  };

  let icon = null;
  let buttonName = 'Sign in';
  let extraStyles = {};
  if (connector.isLoading) {
    icon = <LoaderIcon />;
  } else if (integrationTitle === 'Google Calendar') {
    icon = <GoogleIcon className="absolute left-4 w-5 h-5" />;
    buttonName = 'Sign In with Google';
    extraStyles = {
      fontFamily: 'Roboto, sans-serif',
      backgroundColor: 'white',
    };
  }

  return (
    connector.customButton ?? (
      <div className="max-w-[410px]">
        <Button
          leftContent={icon}
          onClick={async () => {
            // it is super important to call connect before other logic
            // because otherwise popup will be blocked by browser
            connector.connect();

            if (integrationTitle) {
              updateHubspotContact({
                email: user.email,
                properties: [
                  {
                    property: 'integrations',
                    value: integrationTitle?.toLowerCase().replace(' ', '-'),
                  },
                ],
              }).catch((e) =>
                console.error('Unable to update hubspot contact', e),
              );
            }
            setIsConnecting(true);
          }}
          disabled={connector.isLoading}
          isFull
          size="small"
          extraStyles={extraStyles}
          text={
            (isHubspot || isPipedrive) && connector.isLoading
              ? getLoadingMessage()
              : buttonName
          }
        />
      </div>
    )
  );
};
