import { User, getAdditionalUserInfo, updateProfile } from '@firebase/auth';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateProfile as updateProfileAction } from '@hints/client';

import { ampli } from '../../ampli';
import { LoaderIcon } from '../components';
import { useAppDispatch } from '../hooks';
import { useQueryParam } from '../hooks/useQueryParam';

export const FinishSignUp = () => {
  const auth = getAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const emailAddress = useQueryParam('email');
  const targetPath = useQueryParam('from');

  const updateUserProfile = async (user: User) => {
    try {
      const { providerData, uid: id } = user;
      if (providerData?.length) {
        const { displayName, email, photoURL } = providerData[0];
        await dispatch(
          updateProfileAction({
            id,
            email,
            name: displayName,
            toltReferral: window.tolt_referral ?? null,
          }),
        );
        await updateProfile(user, {
          displayName,
          photoURL,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = localStorage?.getItem('emailForSignIn');
      if (!email) {
        if (emailAddress) {
          localStorage?.setItem('emailForSignIn', emailAddress);
          email = emailAddress;
        } else {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation');
          if (!email) {
            navigate('/auth');
            return;
          }
          localStorage?.setItem('emailForSignIn', email || '');
        }
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          const { user } = result;
          updateUserProfile(user)
            .then(() => {
              const additionalUserInfo = getAdditionalUserInfo(result);
              if (additionalUserInfo?.isNewUser) {
                ampli.signup({ auth_with: 'email', product: 'hints.so' });
                if (window.tolt && user.email) {
                  window.tolt.signup(user.email);
                }
              } else {
                ampli.login({ auth_with: 'email', product: 'hints.so' });
              }
              localStorage?.removeItem('emailForSignIn');
              navigate(targetPath || '/');
            })
            .catch((error) => {
              console.error(error);
              navigate('/auth');
            });
        })
        .catch((error) => {
          console.error(error);
          navigate('/auth');
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center space-y-3">
      <LoaderIcon />
    </div>
  );
};

export default FinishSignUp;
